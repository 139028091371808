.cursor {
    &-pointer {
        cursor : pointer;
    }
    &-not-allowed, &-not-allowed a {
        cursor: not-allowed !important;
    }
}

.w-fit {
    width: fit-content !important;
}

.text-casing {
    &-capitalize {
        text-transform: capitalize;
    }
}

.ql-editor {
    min-height: 100px;
}
