// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.

@font-face {font-family: "Acta Display"; src: url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.eot"); src: url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.woff") format("woff"), url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8426a04cc01b52b246b8a8e01d168356.svg#Acta Display") format("svg"); }


//----------------------------------------
// Color system
//----------------------------------------
$blue:                            #2D3C60;
$light-blue:                      #4B5776;
$indigo:                          #6610f2;
$purple:                          #926dde;
$pink:                            #e83e8c;
$red:                             #ff4954;
$orange:                          #ffbe00;
$yellow:                          #ffba00;
$green:                           #3cd458;
$teal:                            #20c997;
$cyan:                            #17a2b8;
$gold:                            #c7a17d;

// Context colors
$primary:                         $blue;
$color-primary:                   $blue;
$color-secondary:                 #e9ecf0;
$color-success:                   $green;
$color-info:                      $purple;
$color-warning:                   $yellow;
$color-danger:                    $red;
$color-light:                     #f8f9fa;
$color-dark:                      #191919;


//----------------------------------------
// Background colors
//----------------------------------------
$color-bg-lightest:               #fcfdfe;
$color-bg-lighter:                #f9fafb;
$color-bg-light:                  #f5f6f7;
$color-bg-body:                   #ffffff;
$color-bg-dark:                   #191919;
$color-bg-gray:                   #efefef;


//----------------------------------------
// Text colors
//----------------------------------------
$color-text-darker:               #323d47;
$color-text-dark:                 #555555;
$color-text:                      #757575;
$color-text-light:                #999999;
$color-text-lighter:              #bfc5ca;
$color-text-lightest:             #d3d3d3;
$color-text-secondary:            #929daf;
$color-text-disable:              #a5b3c7;
$color-text-placeholder:          #c9ccce;
$color-title:                     $color-text-darker;
$color-subtitle:                  $color-text-light;


//----------------------------------------
// Fonts
//----------------------------------------
$fonts-dir:                       '/assets/fonts/';
$font-family-base:                'IBM Plex Sans', sans-serif;
$font-family-title:               $font-family-base;

$font-size-base:                  0.9375rem;

$font-weight-base:                300;
$line-height-base:                1.9;

$h1-font-size:                    $font-size-base * 2.75;
$h2-font-size:                    $font-size-base * 2.25;
$h3-font-size:                    $font-size-base * 1.875;
$h4-font-size:                    $font-size-base * 1.625;
$h5-font-size:                    $font-size-base * 1.3125;
$h6-font-size:                    $font-size-base * 1.125;

$h1-font-weight:                  400;
$h2-font-weight:                  400;
$h3-font-weight:                  400;
$h4-font-weight:                  400;
$h5-font-weight:                  400;
$h6-font-weight:                  500;

$display1-size:                   5rem;
$display2-size:                   4rem;
$display3-size:                   3.5rem;
$display4-size:                   3rem;

$display1-weight:                 200;
$display2-weight:                 200;
$display3-weight:                 200;
$display4-weight:                 200;


//----------------------------------------
// Navbar
//----------------------------------------
$navbar-min-height:               56px;
$navbar-offset-top:               10px;


//----------------------------------------
// Other variables
//----------------------------------------

// Feel free to override other variables here. You can see the list of
// all the available variables in `/plugin/thesaas/scss/_variables.scss`

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px
) !default;
.container-fluid {
  max-width: 1280px !important;
}
