.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    outline: none;
    transition: border .24s ease-in-out;
    cursor:pointer;
    p {
        color: #3c4b64;
        margin: 0;
    }
}