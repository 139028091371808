.nav-tabs {
    border-bottom:none;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #fff;
    border-color: #fff;
    border-top-color: #c4c9d0;
    color: #191919;
}

.tab-content {
    border-radius: 0 0.25rem 0.25rem 0.25rem;
    .card {
        border-radius: 0 0.25rem 0.25rem 0.25rem;
    }
}